import React from "react";
import Body from "./Components/Body";
import Footer from "./Components/Footer";
// import Header from "./Components/Header";

function App() {
  return (
    <div>
      {/* <Header /> */}
      <Body />
      <Footer />
    </div>
  );
}

export default App;
