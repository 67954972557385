import React from "react";
import styled from "styled-components";
import { RiArrowRightSLine } from "react-icons/ri";
const Question = () => {
  return (
    <Container>
      <h1>Frequently asked questions</h1>
      <Temp>
        <Hold>
          <RiArrowRightSLine /> <span> Is coding good for kids?</span>
        </Hold>
        <Hold>
          <RiArrowRightSLine /> <span> How do I start my child coding?</span>
        </Hold>
        <Hold>
          <RiArrowRightSLine />{" "}
          <span> What age is best to introduce coding to kids?</span>
        </Hold>
        <Hold>
          <RiArrowRightSLine />{" "}
          <span> Which is the best coding for kids program?</span>
        </Hold>
        <Hold>
          <RiArrowRightSLine /> <span> Why is coding needed?</span>
        </Hold>
        <Hold>
          <RiArrowRightSLine />{" "}
          <span> Why should we use Tynker coding for kids?</span>
        </Hold>
        <Hold>
          <RiArrowRightSLine /> <span> How do I teach my 7 year old code?</span>
        </Hold>
        <Hold>
          <RiArrowRightSLine />{" "}
          <span> What options are there for coding for teens?</span>
        </Hold>
        <hr />
      </Temp>
    </Container>
  );
};

export default Question;

const Container = styled.div`
  width: 100%;
  height: 550px;
  background-color: #f4f9fa;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    margin-top: 30px;
  }
`;
const Hold = styled.div`
  display: flex;
  align-items: center;
  font-size: 20.5px;
  margin-bottom: 20px;

  display: flex;

  hr {
    width: 300px;
  }
`;
const Temp = styled.div`
  width: 428px;
  /* height: 50px; */
  /* background-color: red; */
  /* border-bottom: 1px dotted black; */
`;
